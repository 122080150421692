<script lang='ts'>

  import { fetchData } from '@/helpers/fetchHelpers'
  import { notifier } from '@/helpers/notifier'

  import CountDoc from '@/components/docs/admin/dev/CountDoc.svelte'
  import Documentation from '@/components/Documentation.svelte'

  document.title = 'Count'

  const dir = 'api'
  let data: any = {}

  const load = async (params: any) => {
    const postData = await fetchData('admin/count', { params })
    document.title = 'Count :' + params.dir
    if (postData.error) {
      notifier.info(postData.error)
    } else {
      data = postData
      data.svelte = []
      data.svelte.css = data.filesTableSvelte.filter((el: any) => el[2] === 'css').sort((a: any, b: any) => b[0] - a[0])
      data.svelte.html = data.filesTableSvelte.filter((el: any) => el[2] === 'html').sort((a: any, b: any) => b[0] - a[0])
      data.svelte.js = data.filesTableSvelte.filter((el: any) => el[2] === 'js').sort((a: any, b: any) => b[0] - a[0])

    }
  }
  load({ dir })

  function toKb(el: number, key: string) {
    if (key === 'bytes' || key === 'bytesNoSpace') {
      if (el > 1024) return Math.round(el / 1024) + ' KB'
    }
    return el
  }
</script>

APPS:
<button type='button' on:click={() => load({ dir: 'api' })}>API</button>
<button type='button' on:click={() => load({ dir: 'kit' })}>KIT</button>
<button type='button' on:click={() => load({ dir: 'front' })}>FRONT</button>
<button type='button' on:click={() => load({ dir: 'mod' })}>MOD</button>
<button type='button' on:click={() => load({ dir: 'models' })}>Models</button>
:::
<button type='button' on:click={() => load({ dir: 'all' })}>ALL</button>
<hr />
{#if data.fileCounter}
  {@const svelteFilesCount = data.perType.filter((el) => el[0] === 'svelte')?.[0]?.[2] || 0}
  <strong>{data.totalLines}</strong> lines in the folder <strong>{data.dir}</strong><br />
  <strong>{(data.totalLines - data.fileCounter.gen.commentedLines - data.fileCounter.gen.blankLines)}
  </strong> actual lines of code (not a comment or blank line)<br /><br />
  <div class='horizontal-row'>
    <div>
      <table class='table'>
        {#each Object.keys(data.fileCounter.gen) as key}
          <tr>
            <td>{key}</td>
            <td class='-right'>{toKb(data.fileCounter.gen[key], key)}</td>
          </tr>
        {/each}
      </table>
      <h3>Excluded dirs</h3>
      {#each data.ignoredDirs as dir}
        <div>{dir}</div>
      {/each}
      <h3>Excluded files</h3>
      {#each data.ignoredFiles as file}
        <div>{file}</div>
      {/each}

    </div>

    <div>
      <h3>Per type</h3>
      <table class='table'>
        <tr>
          <th />
          <th>Files</th>
          <th>Lines</th>
          <th>Avg</th>
        </tr>
        {#each data.perType as el}
          <tr>
            <td>{el[0]}</td>
            <td class='-right'>{el[2]}</td>
            <td class='-right'>{el[1]}</td>
            <td class='-right'>{el[3]}</td>
          </tr>
        {/each}
        <tr>
          <th colspan='4'>Svelte</th>
        </tr>
        <tr>
          <th>JS</th>
          <td rowspan='3'>{svelteFilesCount}</td>
          <td>{data?.svelteBlocks?.js}</td>
          <td>{svelteFilesCount > 0 ? Math.ceil(data?.svelteBlocks?.js / svelteFilesCount) : 0}</td>
        </tr>
        <tr>
          <th>HTML</th>
          <td>{data?.svelteBlocks?.html}</td>
          <td>{svelteFilesCount > 0 ? Math.ceil(data?.svelteBlocks?.html / svelteFilesCount) : 0}</td>
        </tr>
        <tr>
          <th>CSS</th>
          <td>{data?.svelteBlocks?.css}</td>
          <td>{svelteFilesCount > 0 ? Math.ceil(data?.svelteBlocks?.css / svelteFilesCount) : 0}</td>
        </tr>
      </table>

      <h3>Extension</h3>
      <table class='table'>
        <tr>
          <th>Extension</th>
          <th>Files</th>
        </tr>
        {#each data.extTable as el}
          <tr>
            <td>{el[0]}</td>
            <td class='-right'>{el[1]}</td>
          </tr>
        {/each}
      </table>
    </div>

    <div>
      <h3>Per file</h3>
      <table class='table'>
        <tr>
          <th>Lines</th>
          <th>File</th>
          <th title='filesize'>Fz</th>
          <th title='filesize spaces excluded'>FzNs</th>
        </tr>
        {#each data.filesTable as el}
          <tr>
            <td class='-right'>{el[0]}</td>
            <td>{el[1]}</td>
            <td class='-right'>{el[2]}</td>
            <td class='-right'>{el[3]}</td>
          </tr>
        {/each}
      </table>
    </div>
  </div>


  <h3>Per svelte block</h3>
  <div class='horizontal-row'>
    <div>
      <h4>Css</h4>
      <table class='table'>
        <tr>
          <th>Lines</th>
          <th>File</th>
        </tr>
        {#each data.svelte.css as el}
          <tr>
            <td class='-right'>{el[0]}</td>
            <td>{el[1]}</td>
          </tr>
        {/each}
      </table>
    </div>
    <div>
      <h4>Html</h4>
      <table class='table'>
        <tr>
          <th>Lines</th>
          <th>File</th>
        </tr>
        {#each data.svelte.html as el}
          <tr>
            <td class='-right'>{el[0]}</td>
            <td>{el[1]}</td>
          </tr>
        {/each}
      </table>
    </div>
    <div>
      <h4>Js</h4>
      <table class='table'>
        <tr>
          <th>Lines</th>
          <th>File</th>
        </tr>
        {#each data.svelte.js as el}
          <tr>
            <td class='-right'>{el[0]}</td>
            <td>{el[1]}</td>
          </tr>
        {/each}
      </table>
    </div>
  </div>
{/if}
<div>{@html data.output}</div>

<Documentation dateCompleted='2023-08-26'>
  <CountDoc />
</Documentation>

<style>
  h3 {
    margin: 0;
  }
  td {
    &.-right {
      text-align: right;
    }
  }

  .horizontal-row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }
</style>
